import { isProductStatic, logError } from 'mid-utils';
import { InventorOutputFileInfo, UploadContentResult, inventorOutputTypes } from '../interfaces/inventorAutomation';
import { LogLevel } from '../interfaces/log';
import {
  InputRule,
  ProductDefinition,
  ProductDefinitionPublishResult,
  PublishStatus,
} from '../interfaces/productDefinitions';
import { DataCategory, uploadFile } from './cloudStorage';
import { productDefinitionToPostOrPutProductPayload } from './productDefinitionUtils';
import { deleteFile } from './filesystem';
import { getEngineVersion } from './inventor';
import { logToFile } from './tools';
import {
  PostProductPayload,
  DynamicContent,
  PostVariantInput,
  PostVariantOutput,
  OutputType,
  DCRfaOutput,
  PostVariantPayload,
} from '@adsk/offsite-dc-sdk';
import { DcApiService, InversifyTypes, inversifyContainer } from 'mid-api-services';
import PublishUtils from './publishUtils';
import { ProductPublishData } from '../interfaces/localCache';
import { writeToPublishData } from './localCache';

export const cleanupFiles = async (tempFiles: string[]): Promise<void> => {
  logToFile(`Start clean up files: ${JSON.stringify(tempFiles)}`, LogLevel.Info);
  await Promise.all(
    tempFiles.map(async (filePath) => {
      await deleteFile(filePath);
    }),
  );
  logToFile('End clean up files', LogLevel.Info);
};

/**
 * Publishes a product definition as dynamic content product.
 *
 * @param productDefinition The product definition to be published.
 * @returns An object containing the result of the operation.
 */
export const publishProductFromProductDefinition = async (
  productDefinition: ProductDefinition,
  isCreatingNewRelease: boolean,
  enforceRevitClassification: boolean,
  enableStaticContent: boolean,
): Promise<ProductDefinitionPublishResult> => {
  logToFile('Start publishProductFromProductDefinition', LogLevel.Info);

  const {
    project: { id: projectId },
    topLevelFolder,
    codeBlocksWorkspace,
    rules,
  } = productDefinition;
  let outputFiles: InventorOutputFileInfo[] = [];
  let tempFiles: string[] = [];
  try {
    const t1 = new Date().getTime();

    if (enforceRevitClassification) {
      // we only generate product Thumbnail locally
      const thumbnailFileInfo = await PublishUtils.generateThumbnail(productDefinition);
      outputFiles.push(thumbnailFileInfo);
    } else {
      outputFiles = await PublishUtils.getGeneratedOutputFiles(productDefinition);
    }

    // add temp file path to array, so that we can clean it after publishing
    tempFiles = tempFiles.concat(outputFiles.map((x) => x.filePath));

    const t2 = new Date().getTime();
    // time taken to generate outputs locally
    const timeToGenerateOutputs = t2 - t1;

    // upload the product thumbnail
    const thumbnailFilePath = outputFiles.find((x) => x.type === inventorOutputTypes.THUMBNAIL)!.filePath;
    const thumbnailObjectKey = await PublishUtils.getObjectKeyAfterUploadThumbnail(projectId, thumbnailFilePath);

    // upload the folder zipped
    const datasetObjectKey = await PublishUtils.getObjectKeyAfterUploadTopLevelFolder(projectId, topLevelFolder);
    let codeBlockUploadedResult: UploadContentResult | undefined = undefined;

    //If product definition is static then we don't need to upload the codeBlocksWorkspace
    const productIsDynamicAndCodeBlocksWorkspaceExists =
      enableStaticContent && !isProductStatic(productDefinition) && codeBlocksWorkspace;

    const codeBlocksWorkspaceExists = codeBlocksWorkspace && !enableStaticContent;

    if (productIsDynamicAndCodeBlocksWorkspaceExists || codeBlocksWorkspaceExists) {
      // upload the CodeBlocksWorkspace
      codeBlockUploadedResult = await PublishUtils.getUploadContentResult(
        projectId,
        codeBlocksWorkspace,
        DataCategory.CodeBlocksWorkspace,
      );
      // add temp file path to array, so that we can clean it after publishing
      tempFiles.push(codeBlockUploadedResult.filePath);
    }

    let rulesUploadedResult: UploadContentResult | undefined = undefined;

    if (rules && rules.length > 0) {
      let productRules: InputRule[] = rules;
      // if product definition is static we only upload the form rules
      if (enableStaticContent && isProductStatic(productDefinition)) {
        productRules = rules.filter((rule) => rule.key === 'formRules');
      }

      // upload the rules
      rulesUploadedResult = await PublishUtils.getUploadContentResult(projectId, productRules, DataCategory.Rules);
      // add temp file path to array, so that we can clean it after publishing
      tempFiles.push(rulesUploadedResult.filePath);
    }

    const t3 = new Date().getTime();
    // time taken to upload dataset, thumbnail and rules
    const timeToUploadDatasetFiles = t3 - t2;

    // Inventor application version
    const engineVersion = await getEngineVersion();
    logToFile(`Get inventor engine version ${engineVersion}`, LogLevel.Info);
    // POST product
    const postProductPayload: PostProductPayload = productDefinitionToPostOrPutProductPayload({
      productDefinition,
      thumbnail: thumbnailObjectKey,
      datasetUrn: datasetObjectKey,
      engineVersion,
      codeBlocksWorkspaceKey: codeBlockUploadedResult?.objectKey,
      rulesKey: rulesUploadedResult?.objectKey,
      isConfigurable: enableStaticContent ? productDefinition.isConfigurable : undefined,
    });

    logToFile(`Start post product with payload: ${JSON.stringify(postProductPayload)}`, LogLevel.Info);
    const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
    let publishedProduct: DynamicContent;
    if (isCreatingNewRelease && productDefinition?.latestContentId) {
      publishedProduct = await dcApiService.updateProduct(
        productDefinition.project.id,
        productDefinition?.latestContentId,
        postProductPayload,
      );
    } else {
      publishedProduct = await dcApiService.postProduct(productDefinition.project.id, {
        ...postProductPayload,
        inputs: postProductPayload.inputs,
      });
    }

    logToFile('Post product successfully', LogLevel.Info);
    const t4 = new Date().getTime();
    // time taken to publish product
    const timeToPublishProduct = t4 - t3;

    let timeToPublishVariant = 0;

    // POST variant
    try {
      // postProductPayload.inputs contains inputs with applicable property
      let inputs: PostVariantInput[] = PublishUtils.dynamicContentInputsToPostVariantInputs(postProductPayload.inputs);
      const outputs: PostVariantOutput[] = [];
      // If the product is static, the API expects an empty array for the inputs in postVariant.
      if (enableStaticContent && isProductStatic(productDefinition)) {
        inputs = [];
      }

      // add RFA output if this was requested
      const rfaOutput = publishedProduct.outputs.find((output) => output.type === OutputType.RFA) as DCRfaOutput | undefined;

      // Set all available RFA and Thumbnail outputs to the default variant
      if (rfaOutput && rfaOutput.options && rfaOutput.options.modelStates) {
        for (let i = 0; i < rfaOutput.options.modelStates.length; i++) {
          const modelState = rfaOutput.options.modelStates[i];

          if (enforceRevitClassification) {
            // add RFA output (will be accompanied with Thumbnail output automatically in the API)
            outputs.push({
              type: OutputType.RFA,
              modelState,
            });
          } else {
            const rfaFilePath = outputFiles.find(
              (x) => x.type === inventorOutputTypes.RFA && x.modelState === modelState,
            )!.filePath;
            const rfaObjectKey = await uploadFile(productDefinition.project.id, rfaFilePath!, DataCategory.Outputs);

            outputs.push({
              type: OutputType.RFA,
              urn: rfaObjectKey,
              modelState,
            });

            //Thumbnail outputs
            const thumnailFilePath = outputFiles.find(
              (x) => x.type === inventorOutputTypes.THUMBNAIL && x.modelState === modelState,
            )!.filePath;
            const thumbnailObjectKey = await uploadFile(
              productDefinition.project.id,
              thumnailFilePath!,
              DataCategory.Outputs,
            );

            outputs.push({
              type: OutputType.THUMBNAIL,
              urn: thumbnailObjectKey,
              modelState,
            });
          }
        }
      }

      const postVariantPayload: PostVariantPayload = {
        inputs,
        outputs,
      };

      logToFile(`Start post default variant with payload: ${JSON.stringify(postVariantPayload)}`, LogLevel.Info);
      const variant = await dcApiService.postVariant({
        projectId: publishedProduct.tenancyId,
        productId: publishedProduct.contentId,
        postVariantPayload,
      });

      // if (enforceRevitClassification) {
      /**
       * NOTE: we wait for the default variant to generate outputs successfully
       * before concluding the publish process ONLY for the new workflow
       * (i.e RFA output(s) generated through job processing)
       */

      /*
        logToFile(`Start to poll for variant outputs.`, LogLevel.Info);
        const variantOutputs: VariantOutput[] = await startFixedIntervalPolling(
          dcApiService.getVariantOutputs, // poll function
          areAllVariantOutputsFinished, // condition function to meet before polling exits or timesout
          30000, // interval in milliseconds (30 seconds)
          12, // max retries (12 * 30000 = 360000 => 6 mins timeout)
          variant.tenancyId,
          variant.contentId,
          variant.variantId,
        );

        if (!variantOutputs.every((x) => x.status === OutputStatus.SUCCESS)) {
          throw new Error('One or more variant outputs failed.');
        }
      } 
      */

      if (variant) {
        logToFile('Post default variant successfully', LogLevel.Info);
      }

      const t5 = new Date().getTime();
      // time taken to post variant
      timeToPublishVariant = t5 - t4;
    } catch (postVariantError: unknown) {
      // an error in POST variant won't cause publishing to fail
      logError(postVariantError);
      logToFile(`Fail to post default variant - ${postVariantError}`, LogLevel.Error);
    }

    // Clean up the outputs
    await cleanupFiles(tempFiles);

    logToFile('End publishProductFromProductDefinition', LogLevel.Info);

    //Total time taken to publish product
    const t6 = new Date().getTime();
    const totalTimeToPublish = t6 - t1;

    const publishData: ProductPublishData = {
      productId: publishedProduct.contentId,
      productDefinitionName: productDefinition.name,
      productName: publishedProduct.name,
      release: publishedProduct.release,
      timeToGenerateOutputs,
      timeToUploadDatasetFiles,
      timeToPostProduct: timeToPublishProduct,
      timeToPostVariant: timeToPublishVariant,
      totalTimeTakenToPublishProduct: totalTimeToPublish,
    };

    const publishDataJson = JSON.stringify(publishData);
    await writeToPublishData(publishDataJson);

    return {
      status: PublishStatus.COMPLETE,
      publishedProduct,
    };
  } catch (err) {
    logError(err);
    // log this error to local logs file
    logToFile(`${JSON.stringify(err)}`, LogLevel.Fatal);

    const errorMessage = err instanceof Error ? (err as Error).message : String(err);

    // Clean up the outputs
    await cleanupFiles(tempFiles);

    logToFile('End publishProductFromProductDefinition', LogLevel.Info);

    return {
      status: PublishStatus.FAILURE,
      errorMessage,
    };
  }
};
